<template>
  <div class="users">
    <Breadcrumb name1="实名管理" name2="实名列表" />
    <!-- 卡片视图区域 -->
    <el-card class="box-card">
      <el-row :gutter="20">
        <!-- <el-col :span="7"> -->
        <!-- 搜索与添加区域 -->
        <!-- <el-input placeholder="请输入内容" clearable v-model="queryInfo.query" @clear="getRotemapList">
            <el-button slot="append" icon="el-icon-search" @click="getRotemapList"></el-button>
          </el-input>
        </el-col> -->
        <el-col :span="4">
          <!-- 添加用户区域 -->
          <el-button type="primary" @click="addDialogVisible = true"
            >轮播图添加</el-button
          >
        </el-col>
      </el-row>
      <!-- 用户列表 -->
      <el-table :data="rotemapList" stripe style="width: 100%" border>
        <el-table-column type="index" label="#"></el-table-column>

        <el-table-column label="轮播图" width="100px">
          <template v-slot="scope">
            <!-- <img :src="scope.row.img_url" style="width:100px;height:50px"/> -->
            <el-image
              height="100px"
              max-width="200px"
              :src="scope.row.img_url"
              :preview-src-list="scope.row.srcList"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="link" label="链接"></el-table-column>
        <el-table-column prop="desc" label="轮播描述"></el-table-column>
        <el-table-column prop="sort" label="排序"></el-table-column>

        <el-table-column label="操作" width="180px">
          <template v-slot="scope">
            <el-button
              type="primary"
              size="mini"
              @click="updateRoteMapWindow(scope.row)"
              >修改</el-button
            >
            <el-button
              type="danger"
              size="mini"
              @click="deleteRoteMap(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        @size-change="handleSizeChange"
        :page-size="queryInfo.page_size"
        :page-sizes="[10, 20, 30, 40]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 添加用户对话框 -->
    <el-dialog
      title="添加/修改用户"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addDislogClosed"
    >
      <!-- 内容主题区域 -->
      <el-form label-width="70px" :model="addRotemapForm" ref="addRotemapFormRef" :rules="addRotemapFormRules">
        <el-form-item label="轮播图" prop="img_url">
          <el-upload
            class="upload-demo"
            drag
            action="alert"
            :on-change=handlePreview
            :auto-upload="false"
            :show-file-list="false"
            :multiple="false"
            :limit=1
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              Drop file here or <em>click to upload</em>
            </div>
          </el-upload>
          <div class="block" v-if="file_url">
            <el-image
              style="width: 100px; height: 100px"
              :src="file_url"
            ></el-image>
          </div>
        </el-form-item>
        <el-form-item label="链接" prop="link">
          <el-input v-model="addRotemapForm.link"></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="desc">
          <el-input v-model="addRotemapForm.desc"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model="addRotemapForm.sort"></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部按钮区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addRoteMap">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { addRotemapFormRulesMixin } from '@/common/mixin.js'
import Breadcrumb from 'components/content/breadcrumb/Breadcrumb'
import COS from 'cos-js-sdk-v5'
import { number } from 'echarts'
export default {
  name: 'Rotemap',
  mixins: [addRotemapFormRulesMixin],
  data() {
    return {
      // 获取用户列表的参数对象
      queryInfo: {
        // 搜索值
        status: 0,
        // 当前的页数
        page: 1,
        // 当前每次显示多少条数据
        page_size: 10,
      },
      // 实名信息列表
      // rotemapList: [],

      // 控制用户对话框的显示和隐藏
      addDialogVisible: false,

      //修改时的对象id
      id: '',
      // 添加用户数据的对象
      addRotemapForm: {
        img_url: '',
        link: '',
        desc: '',
        sort: 0,
      },
      // 修改用户消息对话框显示和隐藏
      editDialogVisble: false,
      // 控制分配角色对话框的显示和隐藏
      setRolesDialogVisible: false,
      // 分配轮播列表
      rotemapList: [],
      //数据总条数
      total: 0,
      file: '',
      // bucket: 'rivvoo-1307094776' /* 必须 */,
      // region: 'ap-beijing' /* 存储桶所在地域，必须字段 */,
      key: '/rotemap/',
      file_url:'',
    }
  },
  components: {
    Breadcrumb,
  },
  created() {
    this.getRotemapList()
  },
  methods: {
    handlePreview(file) {
      this.file = file
      this.file_url = URL.createObjectURL(file.raw)
    },

    //获取轮播列表
    async getRotemapList() {
      this.$http({
        method: 'get',
        url: '/api/v1/admin/carousel-items',
        params: this.queryInfo,
        timeout: 10000,
        headers: {
          token: window.sessionStorage.getItem('token'),
          'Content-Type': 'application/json; charset=UTF-8',
        },
      })
        .then((res) => {
          this.$message.success('获取轮播列表成功!')
          // console.log(res)
          this.rotemapList = res.data.list
          this.rotemapList.forEach((item) => {
            item.srcList = []
            item.srcList.push(item.img_url) // 查看大图存放的图片地址
          })
          this.total = res.data.pager.total_rows
        })
        .catch((res) => {
          this.$message.error('获取轮播列表失败!')
        })
    },
    // 监听 page_size 改变事件 每页显示的个数
    handleSizeChange(newSize) {
      // console.log(newSize)
      this.queryInfo.page_size = newSize
      this.getRotemapList()
    },
    // 监听 页码值 改变的事件 当前页面值
    handleCurrentChange(newPage) {
      console.log(newPage)
      this.queryInfo.page = newPage
      this.getRotemapList()
    },
    // 监听Switch状态的改变
    async userStatuChanged(userInfo) {
      // console.log(userInfo)
      // const { data: res } = await this.$http.put(`users/${userInfo.id}/state/${userInfo.mg_state}`)
      // if (res.meta.status !== 200) {
      //   userInfo.mg_state = !userInfo.mg_state
      //   return this.$message.error('更新用户状态失败!')
      // }
      // return this.$message.success('更新用户状态成功!')
    },
    // 监听添加用户的对话框关闭事件
    addDislogClosed() {
      this.$refs.addRotemapFormRef.resetFields()
      this.file_url = ""
    },
    // 点击按钮,添加新用户
    addRoteMap() {
      if (this.id) {
        this.addRotemapForm.id = this.id
      }
      if (this.id == '' && this.file == '') {
        this.$message.error('轮播图未上传,请先选择轮播进行上传')
        return
      }
      // this.$refs.addArtistFormRef.validate(async valid => {
      //   if (!valid) {
      //     return this.$message.error('请将必要的表单项填写完整哦~')
      //   }
      // })

      if (this.addRotemapForm.link == '' || this.addRotemapForm.desc == '') {
        this.$message.error('链接和描述为必填项,请补充完整后重试')
        return false
      }
      let _that = this

      if (this.file) {
        let time = new Date().getTime()
        let out
        do out = Math.floor(Math.random() * 10000)
        while (out < 1000)
        let key_id = time.toString() + out.toString()

        let cos = new COS({
          getAuthorization: function (options, callback) {
            var tx_cos_data = _that.$cookie.get('tx_cos_data')
            tx_cos_data = tx_cos_data ? JSON.parse(tx_cos_data) : ''
            if (tx_cos_data && time < tx_cos_data.result.ExpiredTime / 1000) {
              // let data = JSON.parse(tx_cos_data);
              var credentials = tx_cos_data.result.Credentials
              callback({
                TmpSecretId: credentials.TmpSecretId,
                TmpSecretKey: credentials.TmpSecretKey,
                XCosSecurityToken: credentials.Token,
                ExpiredTime: tx_cos_data.result.ExpiredTime,
              })
            } else {
              _that
                .$http({
                  method: 'get',
                  url: '/api/v1/grab/auth/cos/backend',
                  timeout: 10000,
                  headers: {
                    token: window.sessionStorage.getItem('token'),
                    'Content-Type': 'application/json; charset=UTF-8',
                  },
                })
                .then((res) => {
                  console.log(res)
                  var credentials = res.data.result.Credentials
                  console.log(res)
                  var cookie_data = JSON.stringify(res.data)
                  _that.$cookie.set('tx_cos_data', cookie_data, '1h')
                  callback({
                    TmpSecretId: credentials.TmpSecretId,
                    TmpSecretKey: credentials.TmpSecretKey,
                    XCosSecurityToken: credentials.Token,
                    ExpiredTime: res.data.result.ExpiredTime,
                  })
                })
            }
          },
        })

        let fileName = this.file.name.lastIndexOf('.') //取到文件名开始到最后一个点的长度
        let fileNameLength = this.file.name.length
        let fileFormat = this.file.name.substring(fileName, fileNameLength) //截

        cos.putObject(
          {
            Bucket: this.uploadCos.bucket /* 必须 */,
            Region: this.uploadCos.region /* 存储桶所在地域，必须字段 */,
            Key: this.key + key_id + fileFormat /* 必须 */,
            StorageClass: 'STANDARD',
            Body: this.file.raw, // 上传文件对象
            onProgress: function (progressData) {
              // console.log(1111111)
              let return_data = JSON.stringify(progressData)
              // console.log(progressData)
              // https://rivvoo-1307094776.cos.ap-beijing.myqcloud.com/entity/fa57cfaf29e5f619

              //  console.log(_that.addForm.img_url)
            },
          },
          function (err, data) {
            if (data.statusCode == 200) {
              _that.addRotemapForm.img_url =
                'https://' +
                _that.uploadCos.bucket +
                '.cos.' +
                _that.uploadCos.region +
                '.myqcloud.com' +
                _that.key +
                key_id +
                fileFormat
              if (_that.id) {
                _that.updateRote()
              } else {
                _that.addRotemapForm.sort = _that.addRotemapForm.sort * 1
                _that
                  .$http({
                    method: 'post',
                    url: '/api/v1/admin/carousel-items',
                    data: _that.addRotemapForm,
                    timeout: 10000,
                    headers: {
                      token: window.sessionStorage.getItem('token'),
                      'Content-Type': 'application/json; charset=UTF-8',
                    },
                  })
                  .then((res) => {
                    _that.$message.success('新增成功')
                    _that.file = ''
                    _that.addDialogVisible = false
                    _that.getRotemapList()
                  })
                  .catch((res) => {
                    return _that.$message.error(
                      '新增操作失败了哦,请检查数据后重试'
                    )
                  })
              }
            }
          }
        )
      } else {
        _that.updateRote()
      }
    },
    updateRote() {
      const _that = this
      _that.addRotemapForm.sort = _that.addRotemapForm.sort * 1
      _that
        .$http({
          method: 'put',
          url: '/api/v1/admin/carousel-items/' + _that.id,
          data: _that.addRotemapForm,
          timeout: 10000,
          headers: {
            token: window.sessionStorage.getItem('token'),
            'Content-Type': 'application/json; charset=UTF-8',
          },
        })
        .then((res) => {
          _that.$message.success('修改数据成功')
          _that.file = ''
          _that.addDialogVisible = false
          _that.getRotemapList()
        })
        .catch((res) => {
          return _that.$message.error('修改操作失败了哦,请检查数据后重试')
        })
    },
    updateRoteMapWindow(row) {
      this.addDialogVisible = true
      this.id = row.id
      this.addRotemapForm = {
        img_url: row.img_url,
        link: row.link,
        desc: row.desc,
        sort: row.sort,
      }
      this.file_url = row.img_url
    },

    deleteRoteMap(id) {
      this.$http({
        method: 'delete',
        url: '/api/v1/admin/carousel-items/' + id,
        timeout: 10000,
        headers: {
          token: window.sessionStorage.getItem('token'),
          'Content-Type': 'application/json; charset=UTF-8',
        },
      })
        .then((res) => {
          this.$message.success('删除轮播成功!')
          this.getRotemapList()
        })
        .catch((res) => {
          this.$message.error('删除轮播失败!')
        })
    },
    // 展示编辑用于的对话框
    // async showEditDialog(id) {
    //   this.$http
    //     .get('artists/' + id)
    //     .then((res) => {
    //       this.id = id
    //       // console.log(res.data.id)
    //       this.addRotemapForm = {
    //         img_url: res.data.img_url,
    //         link: res.data.link,
    //         desc: res.data.desc,
    //       }
    //       this.addDialogVisible = true
    //       this.file_url = res.data.img_url
    //     })
    //     .catch(function (response) {
    //       this.$message.error('查询用户数据失败~')
    //     })
    //   // this.editForm = res
    //   // console.log(this.editForm)
    //   // this.editDialogVisble = true
    //   // return this.$message.success('查询用户数据成功~')
    // },
    // 监听修改用户对话框的关闭事件
    // aditClosed() {
    //   this.$refs.editFormRef.resetFields()
    // },
    // editUserInfo() {
    //   this.$refs.editFormRef.validate(async valid => {
    //     console.log(valid)
    //     if (!valid) return
    //     // 发起修改用户信息的数据请求
    //     const { data: res } = await this.$http.put('users/' + this.editForm.id, {
    //       email: this.editForm.email,
    //       mobile: this.editForm.mobile
    //     })
    //     if (res.meta.status !== 200) {
    //       this.$message.error('更新用户信息失败!')
    //     }
    //     this.editDialogVisble = false
    //     this.getRotemapList()
    //     this.$message.success('更新用户信息成功!')
    //   })
    // },
    // 根据id删除对应的用户信息
    // async removeUserById(id) {
    //   // 询问用户是否删除用户
    //   const confirmRusult = await this.$confirm(
    //     '此操作将删除该艺术家?',
    //     '删除该艺术家',
    //     {
    //       confirmButtonText: '确定',
    //       cancelButtonText: '取消',
    //       type: 'warning',
    //     }
    //   ).catch((err) => err)
    //   console.log(confirmRusult)
    //   // 用户点击了删除,则返回字符串 confirm
    //   // 用户取消了删除,则返回字符串 cancel
    //   if (confirmRusult !== 'confirm') {
    //     return this.$message.info('已经取消了删除')
    //   }
    //   this.$http
    //     .delete('artists/' + id)
    //     .then((res) => {
    //       const { data: response } = res
    //       this.$message.success('该艺术家已经删除')
    //       this.getArtistList()
    //     })
    //     .catch((res) => {
    //       return this.$message.error('该艺术家删除失败')
    //     })
    // },
    // 展示分配角色的对话框
    // async setRoles(userInfo) {
    //   this.userInfo = userInfo
    //   // 再展示对话框之前获取所有的角色列表
    //   this.$http.get('roles').then((res) => {
    //     const { data: response } = res
    //     // console.log(response)
    //     // if (res.meta.status !== 200) {
    //     //   return this.$message.error('获取角色列表失败!')
    //     // }
    //     this.rolesList = res.data.list
    //     console.log(this.rolesList)
    //     this.setRolesDialogVisible = true
    //   })
    // },
    // 点击按钮,分配角色
    // async saveRolesInfo() {
    //   if (!this.selectRoleId) {
    //     return this.$message.error('请选择要分配的角色!')
    //   }
    //   const { data: res } = await this.$http.put(
    //     `users/${this.userInfo.id}/role`,
    //     {
    //       rid: this.selectRoleId,
    //     }
    //   )
    //   if (res.meta.status !== 200) {
    //     return this.$message.error('更新角色失败!')
    //   }
    //   this.$message.success('更新角色成功!')
    //   this.getRotemapList()
    //   this.setRolesDialogVisible = false
    // },
    // // 分配角色对话框关闭
    // setRolesDialogClosed() {
    //   this.selectRoleId = ''
    //   this.userInfo = ''
    // },
  },
}
</script>

<style lang="less" scoped>
.el-table {
  margin-top: 15px;
}
</style>

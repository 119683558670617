<template>
  <div class="users">
    <Breadcrumb name1="实名管理" name2="实名列表" />
    <div>
      <el-select v-model="realType" placeholder="请选择" @change="hundleRealNameType">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </div>
    <!-- 卡片视图区域 -->
    <el-card class="box-card">
      <el-row :gutter="20">
      </el-row>
      <!-- 用户列表 -->
      <el-table :data="certList" stripe style="width: 100%" border>
        <el-table-column type="index" label="#"></el-table-column>

        <el-table-column prop="u_name" label="用户名"></el-table-column>
        <!-- <el-table-column prop="email" label="邮箱"></el-table-column> -->
        <el-table-column prop="id_no" label="身份证号"></el-table-column>

        <el-table-column label="身份证正面" width="100px">
          <template v-slot="scope">
            <!-- <img :src="scope.row.id_z" style="width: 100px; height: 50px" /> -->
            <el-image
              height="50px"
              max-width="100px"
              :src="scope.row.id_z"
              :preview-src-list="scope.row.id_z_list"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column label="身份证反面" width="100px">
          <template v-slot="scope">
            <!-- <img :src="scope.row.id_f" style="width: 100px; height: 50px" /> -->
            <el-image
              height="50px"
              max-width="100px"
              :src="scope.row.id_f"
              :preview-src-list="scope.row.id_f_list"
            >
            </el-image>
          </template>
        </el-table-column>
        <!-- <el-table-column label="身份证手持" width="100px">
          <template v-slot="scope">
            <img :src="scope.row.id_sc" style="width: 100px; height: 50px" />
          </template>
        </el-table-column> -->
        <el-table-column label="审核状态" width="100px">
          <template v-slot="scope">
            <!-- <div>
              {{
                scope.row.status == 0? '未审核': scope.row.bill_status == 1? '已通过': scope.row.bill_status == 2? '未通过': '封号'
              }}
            </div> -->
            <div>
              {{
                scope.row.status | formatStatus
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180px">
          <template v-slot="scope">
            <el-button
              v-if="(checkStatus(scope.row.status), 1)"
              type="primary"
              size="mini"
              @click="checkUser(scope.row.uid, 1)"
              >通过</el-button
            >
            <el-button
              v-if="(checkStatus(scope.row.status), 2)"
              type="success"
              size="mini"
              @click="checkUser(scope.row.uid, 2)"
              >拒绝</el-button
            >
            <el-button
              v-if="(checkStatus(scope.row.status), 3)"
              type="danger"
              size="mini"
              @click="checkUser(scope.row.uid, 3)"
              >封号</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        @size-change="handleSizeChange"
        :page-size="queryInfo.page_size"
        :page-sizes="[10, 20, 30, 40]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 添加用户对话框 -->
    <!-- <el-dialog title="添加/修改用户" :visible.sync="addDialogVisible" width="50%" @close="addDislogClosed">
      <el-form label-width="70px" ref="addArtistFormRef" :model="addForm" :rules="addArtistFormRules">
        <el-form-item label="头像" prop="avatar">
          <el-upload
  class="upload-demo"
  drag
  action="alert"
:on-change="handlePreview"
:auto-upload="false"
:show-file-list="true"
:multiple="false"
  >
  <i class="el-icon-upload"></i>
  <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
</el-upload>
        
        </el-form-item>
        <el-form-item label="昵称" prop="nickname">
          <el-input v-model="addForm.nickname"></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="desc">
          <el-input v-model="addForm.desc"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUser">确 定</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import Breadcrumb from 'components/content/breadcrumb/Breadcrumb'
import COS from 'cos-js-sdk-v5'
export default {
  name: 'Artists',
  data() {
    return {
      // 获取用户列表的参数对象
      queryInfo: {
        // 搜索值
        status: 0,
        // 当前的页数
        page: 1,
        // 当前每次显示多少条数据
        pageNum: 10,
      },
      // 实名信息列表
      certList: [],

      // 控制用户对话框的显示和隐藏
      addDialogVisible: false,
      //修改时的对象id
      id: '',
      // 添加用户数据的对象
      addForm: {
        nickname: '',
        avatar: '',
        desc: '',
      },
      // 修改用户消息对话框显示和隐藏
      editDialogVisble: false,
      // 控制分配角色对话框的显示和隐藏
      setRolesDialogVisible: false,
      // 需要被分配角色的用户信息
      userInfo: {
        nickname: '',
        role: {
          name: '',
        },
      },
      // 分配角色列表
      rolesList: [],
      // 保存已经选中的角色id值
      selectRoleId: '',
      // 查询用户的对象
      editForm: {},
      file: '',
      bucket: 'rivvoo-1307094776' /* 必须 */,
      region: 'ap-beijing' /* 存储桶所在地域，必须字段 */,
      key: '/header/',
      cosModel: '', //cos对像
      options: [{
          value: '-1',
          label: '全部'
        }, {
          value: '0',
          label: '未实名'
        }, {
          value: '1',
          label: '已实名'
        },
        {
          value: '2',
          label: '认证未通过'
        },
        {
          value: '3',
          label: '封号'
        }],
      realType: '-1',
      total: 0
    }
  },
  components: {
    Breadcrumb
  },
  created() {
    this.initCosNew()
    this.getCertList()
  },
  filters: {
    formatStatus (value) {
      let statusText = ''
      switch (value) {
        case 0:
          statusText = '未认证'
          break
        case 1:
          statusText = '已认证'
          break
        case 2:
          statusText = '认证未通过'
          break
        case 3:
          statusText = '封号'
          break
        default:
          statusText = '未知状态'
          break
      }
       return statusText
    }
  },
  methods: {
    // 选择实名认证类型
    hundleRealNameType: function () {
      console.log(this.realType)
      this.getCertList()
    },
    initCosNew() {
      const _that = this
      var tx_cos_data = _that.$cookie.get('tx_cos_data_pri')
      tx_cos_data = tx_cos_data ? JSON.parse(tx_cos_data) : ''
      console.log('tx_cos_data', tx_cos_data)
      let time = new Date().getTime()
      if (tx_cos_data && time / 1000 < tx_cos_data.result.ExpiredTime) {
        _that.cosModel = new COS({
          getAuthorization: function (options, callback) {
            var credentials = tx_cos_data.result.Credentials
            callback({
              TmpSecretId: credentials.TmpSecretId,
              TmpSecretKey: credentials.TmpSecretKey,
              XCosSecurityToken: credentials.Token,
              ExpiredTime: tx_cos_data.result.ExpiredTime,
            })
          }
        })
      } else {
        _that.$http({
          method: 'get',
          url: '/api/v1/grab/auth/cos/backpri',
          timeout: 10000,
          headers: {
            token: window.sessionStorage.getItem('token'),
            'Content-Type':
              'application/x-www-form-urlencoded; charset=UTF-8',
          },
        })
        .then((res) => {
          var credentials = res.data.result.Credentials
          var cookie_data = JSON.stringify(res.data)
          _that.$cookie.set('tx_cos_data_pri', cookie_data, '1h')
          _that.cosModel = new COS({
            getAuthorization: function (options, callback) {
              callback({
                TmpSecretId: credentials.TmpSecretId,
                TmpSecretKey: credentials.TmpSecretKey,
                XCosSecurityToken: credentials.Token,
                ExpiredTime: res.data.result.ExpiredTime
              })
            }
          })
        })
        .catch((res) => {
          _that.$message.error('获取临时私钥失败')
        })
      }
    },
    initCos() {
      const _that = this
      _that.cosModel = new COS({
        getAuthorization: function (options, callback) {
          var tx_cos_data = _that.$cookie.get('tx_cos_data_pri')
          tx_cos_data = tx_cos_data ? JSON.parse(tx_cos_data) : ''
          console.log('tx_cos_data', tx_cos_data)
          let time = new Date().getTime()
          if (tx_cos_data && time / 1000 < tx_cos_data.result.ExpiredTime) {
            var credentials = tx_cos_data.result.Credentials
            callback({
              TmpSecretId: credentials.TmpSecretId,
              TmpSecretKey: credentials.TmpSecretKey,
              XCosSecurityToken: credentials.Token,
              ExpiredTime: tx_cos_data.result.ExpiredTime,
            })
          } else {
            _that.$http({
                method: 'get',
                url: '/api/v1/grab/auth/cos/backpri',
                timeout: 10000,
                headers: {
                  token: window.sessionStorage.getItem('token'),
                  'Content-Type':
                    'application/x-www-form-urlencoded; charset=UTF-8',
                },
              })
              .then((res) => {
                var credentials = res.data.result.Credentials
                var cookie_data = JSON.stringify(res.data)
                _that.$cookie.set('tx_cos_data_pri', cookie_data, '1h')
                callback({
                  TmpSecretId: credentials.TmpSecretId,
                  TmpSecretKey: credentials.TmpSecretKey,
                  XCosSecurityToken: credentials.Token,
                  ExpiredTime: res.data.result.ExpiredTime,
                })
              })
              .catch((res) => {
                _that.$message.error('获取临时私钥失败')
              })
          }
        },
      })
    },
    handlePreview(file) {
      this.file = file
    },
    //审核用户状态
    checkUser(uid, status) {
      this.$http({
        method: 'post',
        url: '/api/v1/grab/admin/id/backcert',
        data: {
          uid: uid,
          status: status,
        },
        timeout: 10000,
        headers: {
          token: window.sessionStorage.getItem('token'),
          'Content-Type': 'application/json; charset=UTF-8',
        },
      })
        .then((res) => {
          this.$message.success('操作成功!')
          this.getCertList()
        })
        .catch((res) => {
          this.$message.error('操作失败!')
        })
    },

    //检查审核状态(status:数据状态;operate:要执行的操作)
    checkStatus(status, operate) {
      if (operate == 1) {
        if (status == 0) {
          return true
        } else {
          return false
        }
      }
      if (operate == 2) {
        if (status == 0) {
          return true
        } else {
          return false
        }
      }
      if (operate == 3) {
        let arr = [0, 1, 2]
        if (arr.indexOf(status) >= 0) {
          return true
        } else {
          return false
        }
      }
    },
    //获取实名认证列表
    async getCertList() {
      const _that = this
      _that.queryInfo.status = _that.realType
      _that.$http({
          method: 'get',
          url: '/api/v1/grab/admin/id/listcert',
          params: _that.queryInfo,
          timeout: 10000,
          headers: {
            token: window.sessionStorage.getItem('token'),
            'Content-Type': 'application/json; charset=UTF-8',
          },
        })
        .then((res) => {
          console.log(res)
          _that.$message.success('获取艺术家列表成功!')
          _that.certList = res.data.result.list
          if (_that.certList != undefined && _that.certList.length > 0) {
            
            for (let index = 0; index < _that.certList.length; index++) {
              _that.certList[index].id_f_list = [_that.certList[index].id_f]
              _that.certList[index].id_z_list = [_that.certList[index].id_z]
              console.log(index, _that.certList[index])
              let substr = 'https://' + _that.uploadCos.bucketPrivate + '.cos.' + _that.uploadCos.region + '.myqcloud.com'
              if (_that.certList[index].id_f != '') {
                let id_f = _that.certList[index].id_f.replace(substr, '')
                _that.cosModel.getObjectUrl(
                  {
                    Bucket: _that.uploadCos.bucketPrivate /* 填入您自己的存储桶，必须字段 */,
                    Region: _that.uploadCos.region /* 存储桶所在地域，例如ap-beijing，必须字段 */,
                    Key: id_f /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */,
                    Sign: true /* 获取带签名的对象URL */,
                    Protocol: 'https:',
                  },
                  function (err, data) {
                    if (err) return console.log(err)
                    /* url为对象访问url */
                    _that.certList[index].id_f = data.Url
                    _that.certList[index].id_f_list = [data.Url]
                  }
                )
              }
              // if (_that.certList[index].id_sc != '') {
              //   const id_sc = _that.certList[index].id_sc.replace(substr, '')
              //   _that.cosModel.getObjectUrl(
              //     {
              //       Bucket:
              //         _that.uploadCos
              //           .bucketPrivate /* 填入您自己的存储桶，必须字段 */,
              //       Region:
              //         _that.uploadCos
              //           .region /* 存储桶所在地域，例如ap-beijing，必须字段 */,
              //       Key: id_sc /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */,
              //       Sign: true /* 获取带签名的对象URL */,
              //     },
              //     function (err, data) {
              //       if (err) return console.log(err)
              //       /* url为对象访问url */
              //       _that.certList[index].id_sc = data.Url
              //     }
              //   )
              // }
              if (_that.certList[index].id_z != '') {
                const id_z = _that.certList[index].id_z.replace(substr, '')
                _that.cosModel.getObjectUrl(
                  {
                    Bucket: _that.uploadCos.bucketPrivate /* 填入您自己的存储桶，必须字段 */,
                    Region: _that.uploadCos.region /* 存储桶所在地域，例如ap-beijing，必须字段 */,
                    Key: id_z /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */,
                    Sign: true /* 获取带签名的对象URL */,
                  },
                  function (err, data) {
                    if (err) return console.log(err)
                    /* url为对象访问url */
                    _that.certList[index].id_z = data.Url
                    _that.certList[index].id_z_list = [data.Url]
                  }
                )
              }
            }
            console.log(_that.certList)
          }
          // this.userData.total = res.data.pager.total_rows
          _that.total = res.data.result.total
        })
        .catch((err) => {
          // console.log(err)
          _that.$message.error('获取列表错误!')
        })
    },
    // 监听 page_size 改变事件 每页显示的个数
    handleSizeChange(newSize) {
      // console.log(newSize)
      this.queryInfo.pageNum = newSize
      this.getCertList()
    },
    // 监听 页码值 改变的事件 当前页面值
    handleCurrentChange(newPage) {
      console.log(newPage)
      this.queryInfo.page = newPage
      this.getCertList()
    },
    // 监听Switch状态的改变
    async userStatuChanged(userInfo) {
      // console.log(userInfo)
      // const { data: res } = await this.$http.put(`users/${userInfo.id}/state/${userInfo.mg_state}`)
      // if (res.meta.status !== 200) {
      //   userInfo.mg_state = !userInfo.mg_state
      //   return this.$message.error('更新用户状态失败!')
      // }
      // return this.$message.success('更新用户状态成功!')
    },
    // 监听添加用户的对话框关闭事件
    addDislogClosed() {
      this.$refs.addArtistFormRef.resetFields()
    },
    // 展示编辑用于的对话框
    async showEditDialog(id) {
      this.$http
        .get('artists/' + id)
        .then((res) => {
          this.id = id
          console.log(res.data.id)
          this.addForm = {
            avatar: res.data.avatar,
            nickname: res.data.nickname,
            desc: res.data.desc,
          }
          this.addDialogVisible = true
        })
        .catch(function (response) {
          this.$message.error('查询用户数据失败~')
        })
      // this.editForm = res
      // console.log(this.editForm)
      // this.editDialogVisble = true
      // return this.$message.success('查询用户数据成功~')
    },
    // 监听修改用户对话框的关闭事件

    // 根据id删除对应的用户信息

    // 分配角色对话框关闭
    setRolesDialogClosed() {
      this.selectRoleId = ''
      this.userInfo = ''
    },
  },
}
</script>

<style lang="less" scoped>
.el-table {
  margin-top: 15px;
}
</style>
